import React, { useEffect } from "react";
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Matrix from "../components/Matrix"
// Layout
import Weather from "../components/Weather";
const TestWeatherApi = () => {
    return (
        // <Layout location={'test-weather-api'} title={'test'}>
            <Weather></Weather>
        // </Layout>
    );
};

export default TestWeatherApi
